import React from "react";
import Link from "next/link";

const OffCanvasMenu = () => {
  return (
    <div className="offcanvas-body">
      <ul className="nav col-12 col-md-auto justify-content-center main-menu">
   {/*     <li>
          <Link href="#">
            <a className="nav-link">Über uns</a>
          </Link>
        </li>
        <li>
          <Link href="#">
            <a className="nav-link">Neuigkeiten</a>
          </Link>
        </li>

        <li>
          <Link href="#">
            <a className="nav-link">Projekte</a>
          </Link>
        </li>*/}
      </ul>
      <div className="action-btns mt-4 ps-3">
        <Link href="/contact">
          <a className="btn btn-primary">Jetzt Mitmachen</a>
        </Link>
      </div>
    </div>
  );
};

export default OffCanvasMenu;
