import Link from "next/link";
import React from "react";

const HeroSectionMain = () => {
  return (
    <>
      <style jsx>{`
        .banner-bg {
          background: url("/img/banner/forrest-1.jpg") no-repeat center center;
          background-size: cover;
        }
      `}</style>

      <section className="hero-section h-100 hero-ten ptb-120 position-relative overflow-hidden banner-bg">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-8 col-lg-10 mb-5">
              <div className="hero-content-wrap">
                <h5 className="text-warning">Gemeinsam schneller</h5>
                <h1 className="fw-bold display-5 text-white" data-aos="fade-up">
                  Wir begleiten klimapositive Produzenten
                </h1>
                <p
                  className="lead text-white-80"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  bei der Verwertung lokaler Rohstoffströme.
                  <br />
                  Wir sind Vertriebspartner für Produkte und Materialien.
                  <br />
                  Wir organisieren den Handel mit CO2-Zertifikaten.
                </p>
                <div
                  className="action-btns text-center pt-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Link href="/contact">
                    <a className="btn btn-primary me-3">Jetzt Mitmachen</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSectionMain;
