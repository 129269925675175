import React from "react";
import Head from "next/head";

type LayoutParameters = {
  children?: any;
  title?: string;
  desc?: string;
  hasClass?: string;
  classOpt?: string;
};

const Layout: React.FC<LayoutParameters> = ({
  children,
  title,
  desc,
  hasClass,
  classOpt,
}) => {
  return (
    <div
      className={`main-wrapper ${
        hasClass ? "overflow-hidden" : null
      } ${classOpt}`}
    >
      <Head>
        <title>
          {title
            ? `klima.bond | ${title}`
            : "klima.bond - Wir begleiten klimapositive Produzenten"}
        </title>
        {desc && <meta name="description" content={desc} />}
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {children}
    </div>
  );
};

export default Layout;
