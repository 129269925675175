import type { NextPage } from "next";
import React from "react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import Layout from "@layout/Layout";
import Navbar from "@layout/Header/Navbar";
import HeroSectionMain from "@components/hero-section/HeroSectionMain";

SwiperCore.use([Autoplay, Navigation]);
const Home: NextPage = () => {
  return (
    <>
      <Layout>
        <Navbar navDark={true} />
        <HeroSectionMain />
        {/*<CustomerLogoSlider/>
                <CyberAbout/>
                <CyberService/>
                <CyberCta/>
                <VideoPromo/>
                <PriceThree/>
                <CyberProduct/>
                <TestimonialSix/>
                <FaqFour/>
                <CyberBlog/>
                <FooterTwo/>*/}
      </Layout>
    </>
  );
};

export default Home;
